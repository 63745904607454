import { inject, Injectable } from '@angular/core';
import {
  arrayRemove,
  arrayUnion,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import {
  CollectionName,
  FunctionName,
} from '@verify/shared-components/helpers';
import {
  Asset,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
  ResendEmailRequest,
} from '@verify/shared-components/models';
import {
  AuthService,
  BatchActionType,
  FirestoreService,
  FunctionService,
} from '@verify/shared-components/services';
import { combineLatest, map, Observable, take } from 'rxjs';
import { ModelService } from './model.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ModelConsentService {
  private firestore = inject(FirestoreService);
  private authService = inject(AuthService);
  private modelService = inject(ModelService);
  private projectService = inject(ProjectService);
  private functionService = inject(FunctionService);

  constructor() {}

  getModelConsent(
    modelConsentId: string,
  ): Observable<ModelConsent | undefined> {
    return this.firestore.getDocument<ModelConsent>(
      `${CollectionName.modelConsents}/${modelConsentId}`,
    );
  }

  getModelConsentsByProject(projectId: string): Observable<ModelConsent[]> {
    return this.firestore.getQuery<ModelConsent>(
      CollectionName.modelConsents,
      where('projectIds', 'array-contains', projectId),
    );
  }

  getModelConsentsByModel(modelId: string): Observable<ModelConsent[]> {
    return this.firestore.getQuery<ModelConsent>(
      CollectionName.modelConsents,
      where('modelId', '==', modelId),
    );
  }

  getGenericModelConsents(): Observable<ModelConsent[]> {
    return this.firestore.getQuery<ModelConsent>(
      CollectionName.modelConsents,
      where('generic', '==', true),
    );
  }

  // getProjectsAndConsent(
  //   modelId: string,
  // ): Observable<Array<{ modelConsent: ModelConsent; projects?: Project[] }>> {
  //   return combineLatest([
  //     this.projectService.getProjects(),
  //     this.getModelConsentsByModel(modelId),
  //   ]).pipe(
  //     map(([projects, modelConsents]) =>
  //       modelConsents
  //         ?.map((modelConsent) => ({
  //           modelConsent,
  //           projects: (modelConsent.projectIds || [])
  //             .map((projectId) =>
  //               projects.find((project) => project.id === projectId),
  //             )
  //             .filter((project) => !!project),
  //         }))
  //         .filter(
  //           (projectModelConsent) =>
  //             projectModelConsent.modelConsent.generic ||
  //             projectModelConsent.projects.length > 0,
  //         )
  //         .sort((a) => (a.modelConsent.generic ? -1 : 1)),
  //     ),
  //   );
  // }
  getProjectsAndConsent(
    modelId: string,
  ): Observable<Array<{ project?: Project; modelConsent: ModelConsent }>> {
    return combineLatest([
      this.projectService.getProjects(),
      this.getModelConsentsByModel(modelId),
    ]).pipe(
      map(([projects, modelConsents]) =>
        projects
          ?.map((project) => ({
            project,
            modelConsent: modelConsents.find((modelConsent) =>
              modelConsent.projectIds.includes(project.id),
            ),
          }))
          .filter((projectModelConsent) => !!projectModelConsent.modelConsent),
      ),
    );
  }

  getModelsAndConsent(
    projectId: string,
  ): Observable<Array<{ model?: Model; modelConsent: ModelConsent }>> {
    return combineLatest([
      this.modelService.getModelsByProject(projectId),
      this.getModelConsentsByProject(projectId),
    ]).pipe(
      map(([models, modelConsents]) =>
        modelConsents?.map((modelConsent) => ({
          modelConsent,
          model: models.find((model) => model.id === modelConsent.modelId),
        })),
      ),
    );
  }

  getGenericModelsAndConsent(): Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }>
  > {
    return combineLatest([
      this.modelService.getModels(),
      this.getGenericModelConsents(),
    ]).pipe(
      map(([models, modelConsents]) =>
        modelConsents?.map((modelConsent) => ({
          modelConsent,
          model: models.find((model) => model.id === modelConsent.modelId),
        })),
      ),
    );
  }

  addProjectToModelConsent(
    modelConsent: ModelConsent,
    projectId: string,
  ): void {
    this.firestore.batchWrite([
      {
        documentPath: `${CollectionName.modelConsents}/${modelConsent.id}`,
        data: { projectIds: arrayUnion(projectId) },
        type: BatchActionType.update,
      },
      {
        documentPath: `${CollectionName.models}/${modelConsent.modelId}`,
        data: { projectIds: arrayUnion(projectId) },
        type: BatchActionType.update,
      },
      {
        documentPath: `${CollectionName.projects}/${projectId}`,
        data: { modelIds: arrayUnion(modelConsent.modelId) },
        type: BatchActionType.update,
      },
    ]);
  }

  removeModelConsent(modelConsent: ModelConsent, projectId: string): void {
    this.firestore
      .getQuery<Asset>(
        CollectionName.assets,
        where('projectId', 'in', modelConsent.projectIds),
        where('modelIds', 'array-contains', modelConsent.modelId),
      )
      .pipe(take(1))
      .subscribe((assets) => {
        this.firestore.batchWrite([
          {
            documentPath: `${CollectionName.projects}/${projectId}`,
            data: { modelIds: arrayRemove(modelConsent.modelId) },
            type: BatchActionType.update,
          },
          {
            documentPath: `${CollectionName.models}/${modelConsent.modelId}`,
            data: { projectIds: arrayRemove(projectId) },
            type: BatchActionType.update,
          },
          modelConsent.generic
            ? {
                documentPath: `${CollectionName.modelConsents}/${modelConsent.id}`,
                data: { projectIds: arrayRemove(projectId) },
                type: BatchActionType.update,
              }
            : {
                documentPath: `${CollectionName.modelConsents}/${modelConsent.id}`,
                type: BatchActionType.delete,
              },
          ...assets.map((asset) => ({
            documentPath: `${CollectionName.assets}/${asset.id}`,
            data: {
              modelIds: arrayRemove(modelConsent.modelId),
              modelTags: arrayRemove(
                asset.modelTags.find(
                  (modelTag) => modelTag.modelId === modelConsent.modelId,
                ),
              ),
            },
            type: BatchActionType.update,
          })),
        ]);
      });
  }

  revokeModelConsent(modelConsent: ModelConsent): void {
    this.firestore.updateDocument<ModelConsent>(
      `${CollectionName.modelConsents}/${modelConsent.id}`,
      {
        status: ModelConsentStatus.revoked,
        revokedDate: Timestamp.now(),
        revokedBy: this.authService.currentUser.id,
      },
    );
  }

  unrevokeModelConsent(modelConsent: ModelConsent): void {
    this.firestore.updateDocument<ModelConsent>(
      `${CollectionName.modelConsents}/${modelConsent.id}`,
      {
        status: ModelConsentStatus.signed,
        revokedDate: null,
        revokedBy: null,
        unrevokedBy: this.authService.currentUser.id,
      },
    );
  }

  resendEmail(modelConsent: ModelConsent): Observable<void> {
    return this.functionService.call<ResendEmailRequest, void>(
      FunctionName.resendEmail,
      {
        tenantId: this.authService.tenantId,
        modelConsentId: modelConsent.id,
      },
    );
  }
}
