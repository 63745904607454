import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  FormTemplate,
  FormTemplateLanguage,
  FormTemplateVariant,
} from '@verify/shared-components/models';
import { Observable } from 'rxjs';
import { FormService } from '../../../services/form.service';
import { HeaderComponent } from '../../shared/header/header.component';

@Component({
  selector: 'app-forms',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggle,
    MatSelectModule,
    EditorModule,
    MatIconModule,
    TranslateModule,
    HeaderComponent,
  ],
  templateUrl: './forms.component.html',
  styleUrl: './forms.component.scss',
})
export class FormsComponent {
  private formService = inject(FormService);
  private formBuilder = inject(FormBuilder);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  forms$: Observable<FormTemplate[]>;
  formTemplateLanguage = FormTemplateLanguage;
  selectedLanguage = FormTemplateLanguage[this.languageOptions[0]];
  expirationYears = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  readonly form = this.formBuilder.group({
    name: ['', Validators.required],
    generic: [false],
    expirationMonths: [null],
    ...this.languageOptions.reduce((accum, curr) => {
      return { ...accum, [FormTemplateLanguage[curr]]: [''] };
    }, {}),
  });

  constructor() {
    this.forms$ = this.formService.getForms();
  }

  get languageOptions(): (keyof typeof FormTemplateLanguage)[] {
    return Object.keys(
      FormTemplateLanguage,
    ) as (keyof typeof FormTemplateLanguage)[];
  }

  getVariantByLanguage(
    form: FormTemplate,
    language: FormTemplateLanguage,
  ): FormTemplateVariant | undefined {
    return form.variants?.find((variant) => variant.language === language);
  }

  onAddForm(): void {
    this.formService.addForm({ name: '' });
  }

  onOpenFormTemplate(formTemplate: FormTemplate): void {
    this.form.patchValue({
      name: formTemplate.name,
      generic: formTemplate.generic || false,
      expirationMonths: formTemplate.expirationMonths || null,
      ...this.languageOptions.reduce((accum, curr) => {
        return {
          ...accum,
          [FormTemplateLanguage[curr]]:
            formTemplate.variants?.find(
              (variant) => variant.language === FormTemplateLanguage[curr],
            )?.text || '',
        };
      }, {}),
    });
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedLanguage =
      FormTemplateLanguage[this.languageOptions[event.index]];
  }

  onSaveVariants(formTemplate: FormTemplate): void {
    this.formService
      .updateForm({
        ...formTemplate,
        name: this.form.get('name').value,
        generic: this.form.get('generic').value,
        expirationMonths: this.form.get('expirationMonths').value,
        variants: this.languageOptions
          .map((lang) => ({
            language: FormTemplateLanguage[lang],
            text: this.form.get(FormTemplateLanguage[lang])?.value || '',
            modificationDate: Timestamp.now(),
          }))
          .filter((variant) => !!variant.text),
      })
      .then(() => {
        this.snackBar.open(
          this.translateService.instant('model-release-form.update-success'),
          null,
          {
            duration: 5000,
          },
        );
      });
  }

  onDeletForm(form: FormTemplate): void {
    this.formService.deleteForm(form.id).then(() => {
      this.snackBar.open(
        this.translateService.instant('model-release-form.delete-success'),
        null,
        {
          duration: 5000,
        },
      );
    });
  }

  trackByForm(_: number, form: FormTemplate): string {
    return form.id;
  }
}
