<ng-container *ngIf="modelRegistrationInfo">
  <ng-container *ngIf="!signing; else loading">
    <h4>{{ "model.personal-information" | translate }}</h4>

    <div class="container form-container" [formGroup]="form">
      <div
        class="variant-select"
        *ngIf="modelRegistrationInfo?.formTemplate?.variants?.length > 1"
      >
        <mat-button-toggle-group
          name="variant"
          [value]="selectedVariant"
          (change)="onChangeVariant($event)"
          hideSingleSelectionIndicator="true"
        >
          <mat-button-toggle
            [value]="variant.language"
            *ngFor="let variant of modelRegistrationInfo.formTemplate?.variants"
            >{{ variant.language }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>

      <mat-form-field>
        <mat-label>{{ "model.first-name" | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "model.last-name" | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>

      <mat-slide-toggle
        *ngIf="modelRegistrationInfo?.allowMinors"
        class="slide-toggle"
        formControlName="minor"
        >{{ "model.minor-check" | translate }}</mat-slide-toggle
      >

      <mat-form-field>
        <mat-label>{{
          (form.get("minor")?.value ? "model.parent-email" : "model.email")
            | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="email"
          [readonly]="modelRegistrationInfo.model ? true : false"
        />
      </mat-form-field>

      <ng-container *ngIf="form.get('minor')?.value">
        <mat-form-field>
          <mat-label>{{ "model.parent-first-name" | translate }}</mat-label>
          <input matInput type="text" formControlName="parentFirstName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "model.parent-last-name" | translate }}</mat-label>
          <input matInput type="text" formControlName="parentLastName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "model.minor-email" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="minorEmail"
            [readonly]="modelRegistrationInfo.model ? true : false"
          />
        </mat-form-field>
      </ng-container>

      <ng-container
        *ngFor="let field of filteredCustomFields"
        formGroupName="customData"
      >
        <ng-container [ngSwitch]="field.type.toString()">
          <mat-form-field *ngSwitchCase="'NUMBER'">
            <mat-label>{{ field.name }}</mat-label>
            <input matInput type="number" [formControlName]="field.name" />
            <mat-hint *ngIf="field.description">{{
              field.description
            }}</mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'DATE'">
            <mat-label>{{ field.name }}</mat-label>
            <input
              matInput
              [formControlName]="field.name"
              [matDatepicker]="customFieldPicker"
            />
            <mat-hint>{{ field.description || "DD-MM-YYYY" }}</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="customFieldPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #customFieldPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'PERMISSIONS'">
            <mat-label>{{ field.name }}</mat-label>
            <mat-select [formControlName]="field.name" multiple>
              <mat-option
                *ngFor="let permission of field.permissions"
                [value]="permission"
                >{{ permission }}</mat-option
              >
            </mat-select>
            <mat-hint *ngIf="field.description">{{
              field.description
            }}</mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchDefault>
            <mat-label>{{ field.name }}</mat-label>
            <input matInput type="text" [formControlName]="field.name" />
            <mat-hint *ngIf="field.description">{{
              field.description
            }}</mat-hint>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <br />
      <mat-label>{{ "model.take-selfie-info" | translate }}</mat-label>
      <input
        class="file-input"
        type="file"
        #selfie
        name="selfie"
        accept="image/*"
        capture="user"
        (change)="onSelectSelfie($event)"
      />
      <button
        class="sign-button"
        mat-flat-button
        color="primary"
        (click)="selfie.click()"
      >
        <mat-icon>photo_camera</mat-icon>{{ "model.take-selfie" | translate }}
      </button>
      <img *ngIf="selfiePreview" class="selfie-preview" [src]="selfiePreview" />
    </div>

    <h4>{{ "model-release-form.model-release-form" | translate }}</h4>

    <div class="container form-container" [formGroup]="form">
      <div #formElement>
        <div [innerHTML]="formText"></div>
        <div class="model-info" *ngIf="form.valid && selfiePreview">
          <img class="model-info-image" [src]="selfieDataUrl" />
          <div class="model-info-container">
            <div class="model-info-row">
              <span>{{ "model.first-name" | translate }}</span
              ><span>{{ form.get("firstName")?.value }}</span>
            </div>
            <div class="model-info-row">
              <span>{{ "model.last-name" | translate }}</span
              ><span>{{ form.get("lastName")?.value }}</span>
            </div>
            <div class="model-info-row" *ngIf="form.get('minor')?.value">
              <span>{{ "model.parent-name" | translate }}</span
              ><span
                >{{ form.get("parentFirstName")?.value }}
                {{ form.get("parentLastName")?.value }}</span
              >
            </div>
            <div class="model-info-row">
              <span>{{
                (form.get("minor")?.value
                  ? "model.parent-email"
                  : "model.email"
                ) | translate
              }}</span
              ><span>{{ form.get("email")?.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button
          [disabled]="form.invalid || !selfiePreview"
          class="sign-button"
          mat-flat-button
          color="primary"
          (click)="onSign()"
        >
          {{ "model-release-form.sign-model-release" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <ng-container *ngIf="!signed">
      <h4>{{ "model-release-form.registration-save" | translate }}</h4>
      <p>{{ "model-release-form.registration-save-info" | translate }}</p>
      <lib-spinner class="loading-spinner"></lib-spinner>
    </ng-container>
    <ng-container *ngIf="signed">
      <h4>{{ "model-release-form.registration-complete" | translate }}</h4>
      <p>
        {{ "model-release-form.registration-complete-info" | translate }}
      </p></ng-container
    >
  </div>
</ng-template>
