<img class="asset-image" [src]="asset.previewUrl" draggable="false" />

<p class="asset-title">{{ asset.name }}</p>

<button
  *ngIf="enableDecline"
  class="decline-check"
  [class.decline-check-checked]="asset.declined"
  mat-stroked-button
  color="primary"
  (click)="onToggleDeclined()"
  cdkOverlayOrigin
>
  {{ "asset.model-decline" | translate }}
  <mat-icon>{{
    asset.declined ? "select_check_box" : "check_box_outline_blank"
  }}</mat-icon>
</button>
