import { AssetFile } from '../shared';
import { ClientTimestamp } from '../shared-types';
import { CustomField } from '../shared/custom-field.model';

export enum TenantFeature {
  partnerPortal = 'PARTNER_PORTAL',
  consentManager = 'CONSENT_MANAGER',
}

export enum TenantToggle {
  assetAutoTagModels = 'ASSET_AUTO_TAG_MODELS',
  assetFaceSuggestions = 'ASSET_FACE_SUGGESTIONS',
  videoUpload = 'VIDEO_UPLOAD',
  minorModels = 'MINOR_MODELS',
}

export interface TenantFprintConfig {
  apiKey: string;
  increasedMaxSize?: boolean;
}

export interface TenantLythoConfig {
  apiKey: string;
  lythoUrl: string;
}

export interface TenantBynderConfig {
  bynderUrl: string;
  clientId: string;
  clientSecret: string;
}

export interface TenantProvider {
  type: 'microsoft';
  tenantId?: string;
}

export interface Tenant {
  id?: string;
  name: string;
  creationDate: ClientTimestamp;
  features: TenantFeature[];
  lythoConfig?: TenantLythoConfig;
  bynderConfig?: TenantBynderConfig;
  fprintApiKey?: string; //deprecated
  deleted?: boolean;
  validSignConfig?: {
    apiKey: string;
    validSignUrl: string;
  };
  customProjectFields?: CustomField[];
  customModelFields?: CustomField[];
  logo?: AssetFile[];
  websiteUrl?: string;
  projectExpireNotification?: number[];
  emailSignature?: string;
  nameSub?: string;
  identityProviders?: TenantProvider[];
  toggles?: TenantToggle[];
  fprintConfig?: TenantFprintConfig;
}
