import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@verify/shared-components/services';

import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantProvider } from '@verify/shared-components/models';
import { AuthCredential, OAuthProvider } from 'firebase/auth';

enum LoginError {
  invalidEmail = 'INVALID_EMAIL',
  invalidPassword = 'INVALID_PASSWORD',
  resetExpired = 'RESET_EXPIRED',
}

@Component({
  selector: 'app-login',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  private authService = inject(AuthService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  private snackBar = inject(MatSnackBar);

  view:
    | 'EMAIL'
    | 'PASSWORD_FORGET'
    | 'PASSWORD_RESET'
    | 'PASSWORD_RESET_COMPLETE'
    | 'SIGNIN_OPTIONS' = 'EMAIL';
  emailVerified = false;
  loginError: LoginError;
  oobCode: string;
  pendingCredential: AuthCredential;

  @ViewChild('passwordInput')
  passwordInput: ElementRef<HTMLInputElement>;

  readonly form = this.formBuilder.nonNullable.group({
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        () => {
          if (this.loginError === LoginError.invalidEmail) {
            this.loginError = null;
            return { doesNotExist: true };
          }
          return null;
        },
      ],
    ],
    password: ['', Validators.required],
  });

  constructor() {
    const { tenantId, mode, oobCode } =
      this.activatedRoute.snapshot.queryParams;
    if (tenantId) {
      console.log(`set temporary tenant: ${tenantId}`);
      this.authService.getAuth().signOut();
      this.authService.getAuth().tenantId = tenantId;
    }
    if (mode === 'resetPassword' && oobCode) {
      this.onVerifyPasswordResetCode(oobCode);
      this.view = 'PASSWORD_RESET';
    } else if ((this.authService.tenant?.identityProviders || []).length > 0) {
      this.view = 'SIGNIN_OPTIONS';
    }
    this.checkRedirectResult();
  }

  get isTenantSet(): boolean {
    return (
      !!this.authService.getAuth().tenantId || !!this.authService.isRootUser
    );
  }

  get identityProviders(): TenantProvider[] {
    return this.authService.tenant?.identityProviders || [];
  }

  onChooseEmailLogin(): void {
    this.view = 'EMAIL';
  }

  onChooseMicrosoftLogin(providerConfig: TenantProvider): void {
    // [START auth_msft_provider_create]
    const provider = new OAuthProvider('microsoft.com');
    // [END auth_msft_provider_create]

    // [START auth_msft_provider_scopes]
    // provider.addScope('mail.read');
    // provider.addScope('calendars.read');
    // [END auth_msft_provider_scopes]

    // [START auth_msft_provider_params_tenant]
    provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: providerConfig.tenantId || 'common',
    });
    // [END auth_msft_provider_params_tenant]

    this.authService.signInWithRedirect(provider);
  }

  onVerifyEmail(): void {
    const { email } = this.form.getRawValue();
    this.authService
      .fetchSignInMethodsForEmail(email)
      .then((methods) => {
        if (methods?.includes('password')) {
          this.emailVerified = true;
          this.loginError = null;
          setTimeout(() => this.passwordInput.nativeElement.focus(), 100);
        } else {
          this.loginError = LoginError.invalidEmail;
          this.form.get('email').updateValueAndValidity();
          this.form.get('email').markAsTouched();
        }
      })
      .catch((error) => {
        this.loginError = LoginError.invalidEmail;
        this.form.get('email').markAsTouched();
      });
  }

  onUsernamePasswordLogin(): void {
    const { email, password } = this.form.value;
    this.authService
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        if (this.pendingCredential) {
          const user = userCredential.user;
          this.authService
            .linkWithCredential(user, this.pendingCredential)
            .then(() => {
              this.snackBar.open(`Accounts linked`, null, { duration: 5000 });
            });
        }
        // ...
        this.router.navigateByUrl('/');
      })
      .catch((error) => {
        this.loginError = LoginError.invalidPassword;
        this.form.patchValue({ password: '' });
        this.form.get('password').markAsTouched();
      });
  }

  onForgotPassword(): void {
    this.view = 'PASSWORD_FORGET';
  }

  onResetPassword(): void {
    this.view = 'PASSWORD_FORGET';
  }

  onSendPasswordReset(): void {
    const { email } = this.form.value;
    // const snackBar = this
    this.authService
      .sendPasswordResetEmail(email)
      .then(() => {
        // Password Reset Email Sent!
        this.snackBar.open('Password Reset Email Sent!', null, {
          duration: 5000,
        });
        this.view = 'EMAIL';
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode == 'auth/invalid-email') {
          this.snackBar.open('Invalid Email', null, { duration: 5000 });
        } else if (errorCode == 'auth/user-not-found') {
          this.snackBar.open('No account exists for this email address', null, {
            duration: 5000,
          });
        }
      });
  }

  onCancelPasswordReset(): void {
    this.view = 'EMAIL';
  }

  onVerifyPasswordResetCode(oobCode: string): void {
    this.authService
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        this.form.patchValue({ email });
        this.oobCode = oobCode;
        // Display a "new password" form with the user's email address
      })
      .catch(() => {
        this.loginError = LoginError.resetExpired;
      });
  }

  onConfirmPasswordReset(): void {
    const { password } = this.form.value;
    this.authService
      .confirmPasswordReset(this.oobCode, password)
      .then(() => {
        this.view = 'PASSWORD_RESET_COMPLETE';
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode == 'auth/weak-password') {
          this.snackBar.open('Weak password', null, { duration: 5000 });
        } else if (errorCode == 'auth/password-does-not-meet-requirements') {
          this.snackBar.open('New password does not meet requirements', null, {
            duration: 5000,
          });
        } else {
          this.snackBar.open(
            'Could not reset password, try again later',
            null,
            { duration: 5000 },
          );
        }
      });
  }

  private checkRedirectResult(): void {
    this.authService
      .getRedirectResult()
      .then((result) => {
        if (!result) return;
        // const credential = OAuthProvider.credentialFromResult(result);
        // if (credential) {
        //   // This gives you a Microsoft Access Token. You can use it to access the Microsoft API.
        //   const token = credential.accessToken;
        //   // You can also retrieve the OAuth ID token.
        //   const idToken = credential.idToken;
        //   oauthToken.textContent = token ?? '';
        // } else {
        //   oauthToken.textContent = 'null';
        // }
        // // The signed-in user info.
        // const user = result.user;
        this.router.navigateByUrl('/');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          this.pendingCredential = error.credential;
          this.snackBar.open(
            `You already have an account with this email. Sign in with your email and password to link these accounts.`,
            null,
            { duration: 5000 },
          );
          this.onChooseEmailLogin();
        } else {
          console.error(error);
          this.snackBar.open(`Error signing in: ${errorMessage}`, null, {
            duration: 5000,
          });
        }
      });
  }
}
