import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { GetModelConsentFormAsset } from '@verify/shared-components/models';

@Component({
  selector: 'app-model-form-asset',
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule],
  templateUrl: './model-form-asset.component.html',
  styleUrl: './model-form-asset.component.scss',
})
export class ModelFormAssetComponent {
  @Input({ required: true })
  asset!: GetModelConsentFormAsset;

  @Input()
  enableDecline: boolean = false;

  @Output()
  declineAsset = new EventEmitter<string>();

  onToggleDeclined(): void {
    this.declineAsset.emit(this.asset.id);
  }
}
