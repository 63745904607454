<app-header [title]="'project.projects' | translate" [hideBackButton]="true">
  <button
    *ngIf="UserRole.projectManagement | hasRole"
    mat-stroked-button
    color="primary"
    routerLink="/models"
  >
    <mat-icon>person</mat-icon> {{ "model.models" | translate }}
  </button>
  <button
    *ngIf="UserRole.projectManagement | hasRole"
    mat-stroked-button
    color="primary"
    (click)="onAddProject()"
  >
    <mat-icon>add</mat-icon> {{ "project.add-project" | translate }}
  </button>
  <button
    mat-stroked-button
    color="primary"
    class="stroked-icon-button-square list-view-button"
    [routerLink]="['/projects']"
    [queryParams]="{ viewMode: viewMode === 'list' ? 'tile' : 'list' }"
  >
    <mat-icon>{{ viewMode === "list" ? "view_list" : "grid_view" }}</mat-icon>
  </button>
</app-header>

<ng-container *ngIf="viewMode === 'tile'; else listView">
  <div class="projects-grid">
    <app-project-tile
      *ngFor="let project of projects$ | async; trackBy: trackByProject"
      [project]="project"
      (deleteProject)="onDeleteProject($event)"
      (openProject)="onOpenProject($event)"
    >
    </app-project-tile>
  </div>
</ng-container>

<ng-template #listView>
  <div class="projects-list">
    <div class="projects-list-toolbar">
      <button mat-icon-button color="primary" [matMenuTriggerFor]="columnMenu">
        <mat-icon>tune</mat-icon>
      </button>
      <button mat-icon-button color="primary" [matMenuTriggerFor]="filterMenu">
        <mat-icon>filter_alt</mat-icon>
      </button>
      <!-- <mat-form-field subscriptSizing="dynamic" class="projects-list-search">
        <mat-label>{{ "search.search" | translate }}</mat-label>
        <input
          matInput
          #searchInput
          type="text"
          (keyup)="onSearchKeyUp($event, searchInput.value)"
        />
        <button
          matPrefix
          mat-icon-button
          color="primary"
          (click)="onSearch(searchInput.value)"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field> -->
      <mat-chip-set>
        <mat-chip *ngFor="let filter of filters">
          <ng-container [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="'status'">{{
              "model.status-" + filter.value.toLowerCase() | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'verified'">{{
              (filter.value ? "asset.verified" : "asset.unverified") | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-exports'">{{
              "asset.not-exported-assets" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-models'">{{
              "asset.untagged-assets" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'search'"
              >{{ "search.search" | translate }} "{{
                filter.value
              }}"</ng-container
            >
            <ng-container *ngSwitchCase="'matches'">{{
              (filter.value ? "model.matches-found" : "model.matches-not-found")
                | translate
            }}</ng-container>
          </ng-container>
          <button matChipRemove (click)="onRemoveFilter(filter.type)">
            <mat-icon>cancel</mat-icon>
          </button></mat-chip
        >
      </mat-chip-set>
      <lib-spinner
        matPrefix
        class="projects-list-spinner"
        *ngIf="isSearching"
        [size]="30"
      ></lib-spinner>
    </div>

    <div class="projects-list-tablecontainer">
      <table
        mat-table
        matSort
        [dataSource]="projectsDatasource$ | async"
        (matSortChange)="onSortChange($event)"
        class="projects-list-table"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.project.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.description" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.project.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.created-by" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdBy$ | async | userName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="enableRevoke">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.enable-revoke" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              (element.project.enableRevoke ? "base.yes" : "base.no")
                | translate
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="models">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.models" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.project.modelIds?.length || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="taggedModels">
          <th mat-header-cell *matHeaderCellDef>
            {{ "model.tagged-models" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.taggedModels$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="pendingModels">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.status-pending" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.pendingModels$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="revokedModels">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.status-revoked" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.revokedModels$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assets">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.assets" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assetCount$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="taggedAssets">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.tagged-assets" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.taggedAssets$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="exportedAssets">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.exported-assets" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.exportedAssets$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modificationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.modification-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.project.modificationDate
                ? (element.project.modificationDate.toDate()
                  | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.expiration-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.project.expirationDate
                ? (element.project.expirationDate.toDate()
                  | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="matches">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "tracking.matches" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assetMatches$ | async }}
          </td>
        </ng-container>

        <ng-container
          *ngFor="let customField of tenant.customProjectFields"
          [matColumnDef]="customField.name"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ customField.name }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              customField.type === "DATE"
                ? (getCustomValue(element.project, customField.name)
                  | timestamp: "mediumDate")
                : getCustomValue(element.project, customField.name)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <!-- <button mat-button (click)="onEditUser(element)">
          {{ "base.edit" | translate }}
        </button>
        <button mat-button (click)="onDeleteUser(element)">
          {{ "base.delete" | translate }}
        </button> -->
          </td>
        </ng-container>

        <tr
          mat-header-row
          class="projects-list-table-header"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          (click)="onOpenProject(element.project)"
          class="projects-list-table-row"
        ></tr>
      </table>
    </div>

    <mat-paginator
      class="projects-list-paginator"
      [pageSizeOptions]="[5, 10, 20]"
      [pageIndex]="currentPage"
      [pageSize]="itemsPerPage"
      [length]="totalItems"
      (page)="onHandlePageEvent($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</ng-template>

<mat-menu #columnMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let column of availableColumns"
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox
      [checked]="displayedColumns.includes(column.name)"
      [indeterminate]="false"
      (change)="onToggleColumn(column.name)"
    >
    </mat-checkbox>
    {{ column.translation | translate }}
  </button>
</mat-menu>

<mat-menu #filterMenu="matMenu">
  <mat-form-field
    class="model-search"
    subscriptSizing="dynamic"
    (click)="$event.stopPropagation()"
  >
    <mat-label>{{ "search.search" | translate }}</mat-label>
    <input
      matInput
      type="text"
      #searchInput
      (keyup)="onSearchKeyUp($event, searchInput.value)"
    />
    <button matSuffix mat-icon-button (click)="onSearch(searchInput.value)">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.signed)"
  >
    <span class="status-filter-signed"></span
    >{{ "model.status-signed" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.pending)"
  >
    <span class="status-filter-pending"></span
    >{{ "model.status-pending" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.revoked)"
  >
    <span class="status-filter-revoked"></span
    >{{ "model.status-revoked" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('verified', false)">
    <mat-icon>question_mark</mat-icon>
    {{ "asset.unverified" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('verified', true)">
    <mat-icon>check</mat-icon>
    {{ "asset.verified" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-exports', null)">
    <mat-icon>ios_share</mat-icon>
    {{ "asset.not-exported-assets" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-models', null)">
    <mat-icon>location_home</mat-icon>
    {{ "asset.untagged-assets" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('matches', true)">
    <mat-icon>language</mat-icon>{{ "model.matches-found" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('matches', false)">
    <mat-icon>language</mat-icon>{{ "model.matches-not-found" | translate }}
  </button>
</mat-menu>
