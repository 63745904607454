export enum IndexName {
  project = 'project',
  model = 'model',
  asset = 'asset',
}

export interface SearchRequest {
  index: IndexName;
  query: SearchRequestQuery;
  filter?: SearchRequestFilter | SearchRequestFilter[];
  orderBys?: SearchRequestOrderBy[];
  from?: number;
  size?: number;
  filterDeleted?: boolean;
}

export interface SearchRequestOrderBy {
  field: string;
  direction?: 'asc' | 'desc';
  mode?: 'min' | 'max' | 'sum' | 'avg' | 'medium';
  fieldType?: 'double' | 'long' | 'date' | 'date_nanos';
  missing?: string | number | boolean;
  nested?: { path: string; filter?: SearchRequestFilter };
}

export interface SearchRequestQuery {
  queryString: string;
  searchFields: string[];
  nested?: { path: string; searchFields: string[] };
}

export interface SearchRequestFilter {
  term?: { [fieldName: string]: unknown };
  terms?: { [fieldName: string]: unknown[] };
  range?: {
    [fieldName: string]: {
      gte?: unknown;
      gt?: unknown;
      lte?: unknown;
      lt?: unknown;
    };
  };
  nested?: { path: string; query: SearchRequestFilter };
}

export interface SearchResponse<T = unknown> {
  hits?: T[];
  maxScore?: number;
  totalHits?: number;
  message?: string;
  code?: number;
}
