<ng-container [ngSwitch]="notification.type">
  <ng-container *ngSwitchCase="MessageType.modelRevokeConsent">
    <mat-icon class="notification-icon">contract_delete</mat-icon>
    <div class="notification-message">
      <h6>{{ "notification.model-revoke-title" | translate }}</h6>
      <p class="text-caption">
        {{ notification.creationDate | timestamp: "medium" }}
      </p>
      <p>
        {{
          "notification.model-revoke-description"
            | translate
              : {
                  name: model$ | async | modelName,
                  project: (project$ | async)?.name,
                }
        }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MessageType.modelAssetDecline">
    <mat-icon class="notification-icon">person_remove</mat-icon>
    <div class="notification-message">
      <h6>{{ "notification.model-decline-title" | translate }}</h6>
      <p class="text-caption">
        {{ notification.creationDate | timestamp: "medium" }}
      </p>
      <p>
        <img class="asset-image" [src]="imageUrl$ | async" draggable="false" />
        {{
          "notification.model-decline-description"
            | translate
              : {
                  name: model$ | async | modelName,
                  asset: (asset$ | async)?.name,
                }
        }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MessageType.assetFingerprintFailed">
    <mat-icon class="notification-icon">fingerprint</mat-icon>
    <div class="notification-message">
      <h6>{{ "notification.asset-fingerprint-failed-title" | translate }}</h6>
      <p class="text-caption">
        {{ notification.creationDate | timestamp: "medium" }}
      </p>
      <p>
        <img class="asset-image" [src]="imageUrl$ | async" draggable="false" />
        {{
          "notification.asset-fingerprint-failed-description"
            | translate
              : {
                  name: (asset$ | async)?.name,
                  error: (asset$ | async)?.fingerprintError,
                }
        }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MessageType.assetResized">
    <mat-icon class="notification-icon">aspect_ratio</mat-icon>
    <div class="notification-message">
      <h6>{{ "notification.asset-resized-title" | translate }}</h6>
      <p class="text-caption">
        {{ notification.creationDate | timestamp: "medium" }}
      </p>
      <p>
        <img
          *ngIf="imageUrl$"
          class="asset-image"
          [src]="imageUrl$ | async"
          draggable="false"
        />
        {{
          (multipleVariables
            ? "notification.asset-resized-description-multiple"
            : "notification.asset-resized-description"
          )
            | translate
              : {
                  name: (asset$ | async)?.name,
                }
        }}
        <ng-container *ngFor="let variables of multipleVariables">
          <br />{{ variables["assetName"] }}
        </ng-container>
      </p>
    </div>
  </ng-container>
</ng-container>
