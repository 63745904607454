import { MediaMatcher } from '@angular/cdk/layout';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Asset,
  AssetFace,
  AssetFileSize,
  AssetStatus,
  Model,
  ModelConsent,
  ModelTag,
  Project,
  TenantToggle,
} from '@verify/shared-components/models';
import {
  HasTogglePipe,
  StorageService,
} from '@verify/shared-components/services';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { ModelNamePipe } from '../../pipes';
import { AssetService, ProjectService } from '../../services';
import { ModelConsentService } from '../../services/model-consent.service';
import { HeaderComponent } from '../shared/header/header.component';
import { ModelListComponent } from '../shared/model-list/model-list.component';
import { ModelSelectMenuComponent } from '../shared/model-select-menu/model-select-menu.component';

@Component({
  selector: 'app-asset',
  imports: [
    CommonModule,
    ModelNamePipe,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    ModelListComponent,
    TranslateModule,
    HeaderComponent,
    RouterModule,
    ModelSelectMenuComponent,
    OverlayModule,
    HasTogglePipe,
  ],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.scss',
})
export class AssetComponent implements OnInit, OnDestroy {
  private assetService = inject(AssetService);
  private projectService = inject(ProjectService);
  private modelConsentService = inject(ModelConsentService);
  private activatedRoute = inject(ActivatedRoute);
  private storageService = inject(StorageService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  overlay = inject(Overlay);

  private destroy$ = new Subject<void>();

  imageUrl$?: Observable<string>;
  videoUrl$?: Observable<string>;
  asset$?: Observable<Asset | undefined>;
  previousAsset$?: Observable<Asset>;
  nextAsset$?: Observable<Asset>;
  project$?: Observable<Project | undefined>;
  modelConsent$?: Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }> | undefined
  >;
  modelsData$?: Observable<
    | Array<{ model?: Model; modelTag: ModelTag; modelConsent: ModelConsent }>
    | undefined
  >;
  selectableModelConsents$: Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }> | undefined
  >;
  suggestions$ = new BehaviorSubject<
    Array<{ modelId: string; distance: number }>
  >([]);

  TenantToggle = TenantToggle;
  AssetStatus = AssetStatus;
  selection?: { x: number; y: number; faceId?: string };
  selectedModel?: Model;
  modelSelectOpen = false;
  mobileQuery: MediaQueryList;

  isvideo = false;
  playVideo = false;

  @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;

  constructor() {
    const changeDetectorRef = inject(ChangeDetectorRef);
    const media = inject(MediaMatcher);

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addEventListener('change', () =>
      changeDetectorRef.detectChanges(),
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$), distinctUntilKeyChanged('assetId'))
      .subscribe(({ assetId }) => {
        this.playVideo = false;
        this.selectedModel = undefined;
        this.selection = undefined;
        this.asset$ = this.assetService
          .getAsset(assetId)
          .pipe(filter((asset) => !!asset));
        this.asset$.pipe(take(1)).subscribe((asset) => {
          this.isvideo = asset.type.startsWith('video');
          if (!this.isvideo && this.modelSelectOpen) {
            this.modelSelectOpen = false;
          }
          const file =
            asset?.files?.find((file) => file.size == AssetFileSize.size1000) ||
            asset?.files?.[0];
          if (file) {
            this.imageUrl$ = this.storageService.getDownloadUrl(file);
          }
          const videoFile = asset?.files?.find(
            (file) => file.size == AssetFileSize.videoStream,
          );
          if (videoFile) {
            this.videoUrl$ = this.storageService.getDownloadUrl(videoFile);
          }

          this.project$ = this.projectService.getProject(asset!.projectId);
          this.modelConsent$ = this.project$.pipe(
            filter((project) => !!project),
            switchMap((project) =>
              this.modelConsentService.getModelsAndConsent(project.id),
            ),
          );
          this.selectableModelConsents$ = combineLatest([
            this.asset$,
            this.modelConsent$,
            this.suggestions$,
          ]).pipe(
            map(([asset, modelConsents, suggestions]) =>
              modelConsents
                .filter(
                  (modelConsent) =>
                    !asset.modelIds?.includes(modelConsent.model?.id),
                )
                .sort(
                  (a, b) =>
                    (suggestions?.find((sug) => sug.modelId === a.model.id)
                      ?.distance || 1) -
                    (suggestions?.find((sug) => sug.modelId === b.model.id)
                      ?.distance || 1),
                ),
            ),
          );
          this.modelsData$ = combineLatest([
            this.asset$,
            this.modelConsent$,
          ]).pipe(
            filter(([, modelConsent]) => !!modelConsent),
            map(([asset, modelConsent]) =>
              (asset.modelTags || []).map((modelTag) => ({
                ...modelConsent!.find(
                  (modelConsentItem) =>
                    modelConsentItem.model?.id === modelTag.modelId,
                ),
                modelTag,
              })),
            ),
          );
          this.assetService.updateAssetMatches(asset.id);
        });

        this.previousAsset$ = this.asset$?.pipe(
          switchMap((asset) =>
            this.assetService.getPreviousAsset(asset.projectId, asset.id),
          ),
        );
        this.nextAsset$ = this.asset$?.pipe(
          switchMap((asset) =>
            this.assetService.getNextAsset(asset.projectId, asset.id),
          ),
        );
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isFaceTagged(face: AssetFace, modelTags: ModelTag[]): boolean {
    return modelTags?.some((modelTag) => modelTag.faceId === face.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickImage(event: any): void {
    const { offsetX, offsetY, srcElement } = event;
    const { clientWidth, clientHeight } = srcElement;
    this.selectedModel = undefined;

    this.suggestions$.next(null);
    this.selectableModelConsents$.pipe(take(1)).subscribe((modelConsents) => {
      if (modelConsents?.length > 0) {
        this.selection = {
          x: (offsetX / clientWidth) * 100,
          y: (offsetY / clientHeight) * 100,
        };
        setTimeout(() => {
          this.modelSelectOpen = true;
          //this.trigger?.openMenu();
        });
      }
    });
  }

  onClickFace(face: AssetFace): void {
    this.selectedModel = undefined;
    combineLatest([this.asset$, this.modelsData$])
      .pipe(take(1))
      .subscribe(([asset, modelsData]) => {
        const modelData = modelsData?.find(
          (modelData) => modelData.modelTag.faceId === face.id,
        );
        if (modelData) {
          this.onSelectModel(modelData.model);
          return;
        }
        this.assetService
          .getSuggestions(asset.projectId, face)
          .subscribe((suggestions) => {
            this.suggestions$.next(suggestions.matches);
            this.selection = {
              x: (face.x + face.width / 2) * 100,
              y: (face.y + face.height / 2) * 100,
              faceId: face.id,
            };
            setTimeout(() => {
              this.modelSelectOpen = true;
            });
          });
      });
  }

  onToggleModelSelect(): void {
    this.modelSelectOpen = !this.modelSelectOpen;
  }

  onToggleVerified(): void {
    this.asset$.pipe(take(1)).subscribe((asset) => {
      this.assetService.verifyAsset(
        asset.id,
        asset.status !== AssetStatus.verified,
      );
    });
  }

  onAddModel(
    model: Model,
    selection?: { x: number; y: number; faceId?: string },
  ): void {
    this.asset$?.pipe(take(1)).subscribe((asset) => {
      if (asset) {
        this.assetService.addModelTag(asset.id, {
          creationDate: Timestamp.now(),
          modelId: model.id,
          positionX: selection?.x || null,
          positionY: selection?.y || null,
          faceId: selection?.faceId || null,
        });
        if (!this.isvideo) {
          this.modelSelectOpen = false;
        }
        this.selection = undefined;
        //this.trigger?.closeMenu();
      }
    });
  }

  onCloseModelSelect(): void {
    this.selection = undefined;
    this.modelSelectOpen = false;
  }

  onSelectModel(model: Model): void {
    if (!model || this.selectedModel?.id === model.id) {
      this.selectedModel = null;
      this.selection = null;
      return;
    }
    this.selectedModel = model;

    this.modelsData$.pipe(take(1)).subscribe((modelsData) => {
      const modelData = modelsData.find(
        (modelData) => modelData.model?.id === model.id,
      )?.modelTag;
      if (modelData) {
        this.selection = {
          x: modelData.positionX || 50,
          y: modelData.positionY || 50,
        };
      }
    });
  }

  onRemoveModel(modelId: string): void {
    combineLatest([this.asset$, this.modelsData$])
      .pipe(take(1))
      .subscribe(([asset, modelsData]) => {
        this.selectedModel = null;
        this.selection = null;
        const modelData = modelsData.find(
          (modelData) => modelData.model?.id === modelId,
        )?.modelTag;
        if (modelData) {
          this.assetService.removeModelTag(asset.id, modelData);
        }
      });
  }

  onPlayVideo(): void {
    this.playVideo = true;
  }

  onBack(): void {
    this.asset$?.pipe(take(1)).subscribe((asset) => {
      this.router.navigate(['project', asset?.projectId]);
    });
  }

  onOpenMatch(url: string): void {
    window.open(url);
  }

  onAutoTag(): void {
    this.asset$?.pipe(take(1)).subscribe((asset) => {
      this.assetService
        .autoTagAssets(asset.projectId, [asset.id])
        .subscribe((modelsTagged) => {
          this.snackBar.open(
            this.translateService.instant('asset.models-tagged', {
              modelsTagged,
            }),
            null,
            { duration: 5000 },
          );
        });
    });
  }
}
