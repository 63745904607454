<ng-container *ngIf="isLoading; else image">
  <div class="asset-loading">
    <lib-spinner [percentage]="loadingPercentage$ | async"></lib-spinner>
  </div>
</ng-container>
<ng-template #image
  ><img
    class="asset-image"
    [src]="imageUrl$ | async"
    (click)="onOpenAsset($event)"
    draggable="false"
/></ng-template>
<div class="asset-checkbox" [class.asset-checkbox-selected]="selected">
  <mat-icon
    *ngIf="selectable"
    class="asset-checkbox-icon"
    (click)="onSelectAsset($event)"
    >{{ selected ? "check_circle" : "radio_button_unchecked" }}</mat-icon
  >
</div>

<div class="asset-models">
  <app-model-tile
    *ngFor="
      let modelConsent of modelConsent$ | async;
      trackBy: trackByModelConsent
    "
    [model]="modelConsent.model"
    [modelConsent]="modelConsent.modelConsent"
    [declined]="asset.modelDeclines?.includes(modelConsent.model.id)"
    size="small"
  ></app-model-tile>
</div>

<p class="asset-title">{{ asset.name }}</p>

<div class="asset-footer">
  <div class="asset-footer-icons">
    <button
      mat-icon-button
      color="primary"
      [matMenuTriggerFor]="menu"
      *ngIf="asset.exports?.length > 0"
    >
      <mat-icon>ios_share</mat-icon>
    </button>
  </div>
  <div class="status-tag" *ngIf="asset.fingerprintId">
    <mat-icon class="status-tag-icon">language</mat-icon>
    <span>{{ (asset.matches || []).length }}</span>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let export of asset.exports">
    <div mat-menu-item (click)="onExportClick(export)">
      <div class="export-row">
        <div>
          <h6>
            {{ export.type === "LYTHO" ? "Lytho" : "Bynder" }}
          </h6>
          <p>{{ export.exportDate | timestamp: "medium" }}</p>
        </div>
        <mat-icon class="export-row-icon">chevron_right</mat-icon>
      </div>
    </div>
  </ng-container>
</mat-menu>
